import React from "react"
import Layout from "../components/layout"
import { AnchorLink } from "gatsby-plugin-anchor-links"

const ThankYou = () => {
  return   <Layout>
    <section id="mainTitle" style={{background: 'white', color: 'black'}}>
      <div className="row">
        <div className="twelve columns">
          <br />
          <h1 className="responsive-headline"  style={{color: 'black', textAlign: 'center'}}>Hvala za poslano povpraševanje!</h1>
         <div style={{textAlign: 'center'}}>
                <span className="elementor-icon-list-icon">
							<i aria-hidden="true" className="fas fa-check"></i>						</span>
           <h5>Odgovorili vam bomo v najkrajšem možnem času!</h5>
           <span>Hvala za vaše zanimanje za naše storitve in vaše zaupanje. Na vaše sporočilo bomo odgovorili v najkrajšem možnem času.</span>
         </div>
          <div className="buttons" style={{marginTop: '40px'}}>
          <AnchorLink className="button thankyou" to="/">Domov</AnchorLink>
          </div>
          <br />
        </div>
      </div>
    </section>
  </Layout>
};

export default ThankYou;